import classes from './index.module.scss';
import React from 'react'
import { Helmet } from 'react-helmet';
import DisclaimerHero from '../../components/DisclaimerHero/DisclaimerHero';
import externalLinks from '../../utils/externalLinks';
import Footer from '../../components/Footer/Footer';

const PolicyPage = () => (
    <div id="investor-container" >
        <Helmet title="HSE - Privacy Policy" />
        <DisclaimerHero />
		<div className={['wrapper', classes.PolicyWrapper].join(' ')} >
            <h2>Privacy Policy</h2>
            <section>
                <div>
                    <h3>I. Who is responsible and how can I contact the data protection officer?</h3>
                    <h4>Contact Data Controller:</h4>
                    <p>Home Shopping Europe GmbH, Münchener Straße 101 h, 85737 Ismaning, Germany.</p>
                    <h4>Contact Data Protection Officer:</h4>
                    <p>datenschutz@hse.de</p>
                </div>
                <div>
                    <h3>II. Your rights as a data subject</h3>
                    <p>Every data subject has the following rights:</p>
                    <ul>
                        <li>Right of access (Art. 15 GDPR)</li>
                        <li>Right to rectification of inaccurate data (Art. 16 GDPR)</li>
                        <li>Right to erasure or a right to be "forgotten" (Art. 17 GDPR)</li>
                        <li>Right to restriction of processing of personal data (Art. 18 GDPR)</li>
                        <li>Right to data portability (Art. 20 GDPR)</li>
                        <li>You can object to the processing of personal data (Art. 21 GDPR). In this case, the objection to the processing of data must be justified.</li>
                        <li>Right to lodge a complaint with a supervisory authority</li>
                        <li>If the processing of data is based on consent, your consent can be revoked at any time with effect for the future.</li>
                    </ul>
                    <p>As the data subject, the easiest way to exercise your rights is by e-mailing to service@hse.de or via the address provided in the imprint.</p>
                </div>
                <div>
                    <h3>III. Purposes of use and legal basis</h3>
                    <p>We process the personal data provided in the contact form in order to provide investors with access for financial company key figures or to answer press inquiries.</p>
                    <p>Art. 6, para. 1, letter b, of the GDPR is the legal basis for processing personal information for the purpose of registration for our information service.</p>
                    <p>In addition, we process the personal data provided in our contact form for purposes of protecting our legitimate interests in accordance with Art. 6, para. 1, letter f of the GDPR. Our legitimate interest is to offer inquirers a simplified way to contact us for any inquiries.</p>
                    <p>There is no legal obligation to provide the personal data. However, in order to grant you access to the Investor Portal, we need the information to clearly identify you as an investor. If you do not provide us with the information, we cannot grant you access.</p>
                </div>
                <div>
                    <h3>IV. Storage period</h3>
                    <p>If you register for investor access, we will delete your personal data when we receive the information that you are no longer an investor.</p>
                    <p>Press inquiries or inquiries that are not related to investor registration will be deleted after the request has been dealt with.</p>
                </div>
                <div>
                    <h3>V. Automated decision-making</h3>
                    <p>There is neither automated decision-making nor profiling.</p>
                </div>
                <div>
                    <h3>VI. Recipients</h3>
                    <p>We use service providers by way of order processing in the provision of services, in particular for the provision, maintenance and care of IT systems.</p>
                </div>
            </section>
        </div>


    <Footer
      links={[
        {
          text: 'ONLINESHOP',
          href: externalLinks.onlineshop,
          target: '_self',
        },
        {
          text: 'KARRIERE',
          href: externalLinks.karriere,
          target: '_self',
        },
        {
          text: 'Kontakt',
          href: externalLinks.kontakt,
          target: '_self',
        },
        {
          text: 'Investor Relations',
          href: externalLinks.investor,
          target: '_self',
        },
      ]}
      socialMediaLinks={[
        {
          icon: {
            src: require('assets/icons/facebook.svg'),
            alt: 'Facebook page',
          },
          href: externalLinks.facebook,
        },
        {
          icon: {
            src: require('assets/icons/instagram.svg'),
            alt: 'Instagram profile',
          },
          href: externalLinks.instagram,
        },
        {
          icon: {
            src: require('assets/icons/pinterest.svg'),
            alt: 'Pinterest profile',
          },
          href: externalLinks.pinterest,
        },
        {
          icon: {
            src: require('assets/icons/youtube.svg'),
            alt: 'Youtube channel',
          },
          href: externalLinks.youtube,
        },
        {
          icon: {
            src: require('assets/icons/linkedin.svg'),
            alt: 'Linkedin profile',
          },
          href: externalLinks.linkedin,
        },
        {
          icon: {
            src: require('assets/icons/xing.svg'),
            alt: 'Xing profile',
          },
          href: externalLinks.xing,
        },
      ]}
      mobileAppsLinks={[
        {
          icon: {
            src: require('assets/images/apple-store.png'),
            alt: 'ios app',
          },
          href: externalLinks.ios_app,
        },
        {
          icon: {
            src: require('assets/images/google-play-store.png'),
            alt: 'android app',
          },
          href: externalLinks.android_app,
        },
      ]}
      subFooterContent={{
        legalLinks: [
          {
            text: 'Impressum',
            href: externalLinks.impressum,
          },
          {
            text: 'Datenschutz',
            href: externalLinks.datenschutz,
          },
        ],
        copyrightText: `Alle Rechte vorbehalten © HSE ${new Date().getFullYear()}`,
      }}
    />

    </div>
)

export default PolicyPage;